<template>
    <base-members
        :group="group"
        :role="role"
        search
        show-since
        :show-add-button="showAddButton"
    >
        <template #title>
            {{ $t('groups.coaches.title') }}
        </template>
    </base-members>
</template>

<script>
import BaseMembers from '@/pages/groups/BaseMembers';
import {Group} from '@/models/vue-mc/Group';
import Role from '@/library/enumerations/Role';

export default {
    name: 'GroupsGroupCoachesIndex',
    components: {BaseMembers},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    data: () => ({
        role: Role.COACH,
    }),
    computed: {
        showAddButton() {
            return this.$me.hasRoles(Role.ADMIN);
        },
        title() {
            return this.showAddButton
                ? this.$t('groups.coaches.manageTitle')
                : this.$t('groups.coaches.title');
        },
    },
};
</script>
